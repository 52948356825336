<template>
  <div
    v-if="!uiStore.formErrorMsg.context || (context && context === uiStore.formErrorMsg?.context)"
    class="FormErrorMessage py-8 text-criticalDark type-sm-medium emtpy:hidden"
  >
    {{ uiStore.formErrorMsg?.message }}
  </div>
</template>

<script setup lang="ts">
import { useUiStore } from '~/store/ui';
defineProps<{
  context?: string,
}>();
const uiStore = useUiStore();

</script>

<style scoped lang="postcss">

</style>
